$grid: (
    type: grid,
    default: default,
    generateGridClassesFromBreakpoints: true,
    columns: 12,
    column-gap: 1rem,
    row-gap: 1.5rem
);
$breakpoints: (
    desktop: 64rem,
    dividedteaser: 48em,
    history: 52em,
    history-lg: 74.3em,
    screenSmall: 767px
);
            @import "STYLE_UTILS/_base";.sw-spacer {
  --sw-spacer-height: 0;
  display: contents;
}

.sw-spacer__spacer {
  content: '';
  display: block;
  grid-column: 1 / -1;
  height: var(--sw-spacer-height);
  margin: 0;
  width: 100%;

  @include media('>=desktop') {
    height: var(--sw-spacer-desktop-height, var(--sw-spacer-height));
  }
}
